import React from 'react';
import LoginForm from './LoginForm';

function Home() {
  return (
    <>
      <h2>Welcome to Pick 2!</h2>
      <LoginForm/>
    </>
  );
}

export default Home;
